import React from "react";
import TextButton from "../../components/Buttons/TextButton";
import useIsMobile from "../../hooks/useIsMobile";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown";

const News = ({ data, pageContext }) => {
  const isMobile = useIsMobile();

  const newsData = data.strapiHaberlers;

  return (
    <section id="single_news">
      <div className="content_container">
        <GatsbyImage
          image={newsData?.haber_resmi?.childImageSharp.gatsbyImageData}
          className="featured_image"
          alt="Haber ile alakalı bir görsel"
        />

        <div className="inner_container">
          <h1>{newsData.haber_basligi}</h1>
          <p className="date">{newsData.haber_yazilma_tarihi}</p>
          <ReactMarkdown className="content" source={newsData.haber_icerigi} />
        </div>
      </div>
      <div className="navigator_button_container">
        {pageContext.previousSlug && (
          <TextButton
            text="Önceki Haber"
            targetRoute={`/haberler/${pageContext.previousSlug}`}
            arrowAlwaysVisible={isMobile}
            leftArrow
          />
        )}
        {pageContext.nextSlug && (
          <TextButton
            text="Sonraki Haber"
            targetRoute={`/haberler/${pageContext.nextSlug}`}
            arrowAlwaysVisible={isMobile}
            rightArrow
          />
        )}
      </div>
    </section>
  );
};

export const query = graphql`
  query SingleNewsTemplate($slug: String!) {
    strapiHaberlers(slug: { eq: $slug }) {
      haber_basligi
      haber_icerigi
      haber_yazilma_tarihi(locale: "tr", formatString: "DD MMMM YYYY")
      id
      slug
      haber_resmi {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  }
`;

export default News;
